<template>
  <a
    v-smooth-scroll="{ duration: 1000, offset: -140 }"
    :href="plan.sub_plans.length > 1 ? '#plan-period' : '#payment-method'"
    @click="$emit('onSelect', plan)"
  >
    <el-card
      v-loading="loading"
      :class="{ 'selected-plan': plan.id === activePlan.id }"
      class="subscription-plan-card"
    >
      <span
        v-if="plan.mini_label === 'COMPLETO'"
        class="ideal-info">Mais usado pelos lojistas!</span>
      <h2
        :style="{ backgroundColor: plan.color }"
        class="plan-title">{{ plan.mini_label }}</h2>
      <!-- <div class="price">
      <small> {{ +plan.price ? 'apenas' : 'grátis' }}</small>
      <span class="plan-price">{{ planPrice | toMoney }}</span>
    </div>-->
      <div class="features">
        <div
          v-for="(feature, index) in plan.features"
          :key="plan.name + index"
          class="feature">
          <span class="text">{{ feature }}</span>
        </div>
      </div>
      <br >
      <div class="actions">
        <el-button
          round
          size="small"
          type="success">Selecionar</el-button>
      </div>
    </el-card>
  </a>
</template>


<script>
import toMoney from "@/helpers/toMoney";
export default {
  filters: {
    toMoney
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    activePlan: {
      type: Object,
      default: () => {}
    },
    plan: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    planPrice() {
      const monthPlan = this.plan.sub_plans.find(plan => plan.interval === 3);
      return monthPlan.price;
    }
  },
};
</script>

<style lang="scss">
  a {
    text-decoration: none;
    color: $preto;
  }
.subscription-plan-card {
  position: relative;
  // padding: 1rem 0;
  box-sizing: content-box;
  border: 4px solid #FFF;
  border-radius: 17px;
  @media screen and(max-width: 768px) {
  margin: 1.2rem 0.6rem 0 0.6rem;
  }
  margin: 1.2rem 0.6rem 32px 0.6rem;
  transition: .4s;
  cursor: pointer;
  @include box-shadow(1);
  &:hover {
  border-radius: 17px;
    border: 4px solid $laranja;
    transform: translateY(-10px);
  }
}
.selected-plan {
  border: 4px solid $laranja;
  border-radius: 17px;
  @media screen and(min-width: 768px) {
  transform: translateY(-10px);
  }
  .features, h2 {
  filter: opacity(100%) !important;
  }
}

.subscription-plan-card .features {
  display: flex;
  flex-flow: row wrap;
  filter: opacity(30%);
  // padding: 0 1rem;
}
.subscription-plan-card .feature {
  text-align: center;
  flex: 0 100%;
  height: 46px;
  display: flex;
  flex-flow: row wrap;
  @include medium-down {
    height: 28px;
  }
  border-bottom: 1px solid rgba($preto, 0.5);

  &:first-of-type {
    border-top: 1px solid rgba($preto, 0.5);
  }
}
.subscription-plan-card .text {
  margin: auto;
  font-weight: 500;
  font-family: $title-font;
  font-size: 14px;
  @include medium-down {
    font-size: 12px;
  }
}
.subscription-plan-card h2 {
  border-radius: 20px !important;
  text-transform: uppercase;
  font-weight: bold;
  width: fit-content;
  padding: 4px 1rem;
  border-radius: 12px;
  color: #fff;
  margin: 0 auto 1rem;
  filter: opacity(30%);
  @include medium-down {
    margin: 0 auto 0.5rem;
    font-size: 14px;
  }
}

.subscription-plan-card .ideal-info {
  color: $laranja;
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  text-align: center;
  margin-bottom: 1rem;
}

.subscription-plan-card .price {
  margin-bottom: 1rem;
  @include medium-down {
    margin-bottom: 0.5rem;
  }
  small {
    display: block;
    color: #9096a4;
    font-size: 14px;
    @include medium-down {
      font-size: 13px;
    }
  }
  span {
    display: block;
    font-size: 28px;
    color: #2e323c;
    font-weight: 700;
    @include medium-down {
      font-size: 15px;
    }
  }
}

.subscription-plan-card .actions {
  text-align: center;
  .el-button {
    font-weight: 700;
    width: 92%;
    border: none;
    background: linear-gradient(90deg, #ff5e44, #ffb731);
  }
}
</style>
