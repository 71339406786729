<template>
  <div
    v-loading="loading"
    class="payment-methods-container"
    style="max-width: 400px; margin: 1rem auto 0 auto;"
  >
    <el-row>
      <el-col
        :xl="24"
        :lg="24"
        :md="24"
        :sm="24"
        :xs="24">
        <el-select
          v-model="paymentMethod"
          class="input-select"
          placeholder="Select"
        >
          <el-option
            v-for="item in paymentMethodsOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-col>
    </el-row>
    <div v-if="paymentMethod === 'card'">
      <el-form
        ref="cardForm"
        :model="cardForm"
        :rules="cardFormRules"
        class="card-form"
        @validate="handleValidate"
        @submit.prevent.native="e => e.preventDefault()">
        <el-row :gutter="20">
          <el-col
            :lg="24"
            :xl="24"
            :sm="24"
            :xs="24">
            <el-form-item
              prop="full_name"
            >
              <i class="full_name" />
              <el-input
                v-model="cardForm.full_name"
                class="credit_card_name"
                name="cc-name"
                autocomplete.native="cc-name"
                data-iugu="full_name"
                placeholder="Titular do cartão" />
            </el-form-item>
          </el-col>
          <el-col
            :xl="24"
            :lg="24"
            :sm="24"
            :xs="24">
            <el-form-item prop="number">
              <i
                :class="currentFlag"
                class="number" />
              <el-input
                v-mask="'#### #### #### ####'"
                v-model="cardForm.number"
                class="credit_card_number"
                name="cardnumber"
                autocomplete.native="cc-number"
                data-iugu="number"
                placeholder="Número do cartão"/>
            </el-form-item>
          </el-col>
          <el-col
            :xl="12"
            :lg="12"
            :sm="12"
            :xs="12">
            <el-form-item
              style="margin-bottom: 22px;"
              prop="expiration"
            >
              <i class="expiration" />
              <el-input
                v-mask="'##/##'"
                v-model="cardForm.expiration"
                class="credit_card_expiration"
                name="cc-exp"
                autocomplete.native="cc-exp"
                data-iugu="expiration"
                placeholder="MM/AA"/>
            </el-form-item>
          </el-col>
          <el-col
            :xl="12"
            :lg="12"
            :sm="12"
            :xs="12">
            <el-form-item prop="verification_value">
              <i class="verification_value" />
              <el-input
                v-mask="'###'"
                v-model="cardForm.verification_value"
                name="cvc"
                autocomplete.native="cc-csc"
                class="credit_card_cvv"
                data-iugu="verification_value"
                placeholder="CVV"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row>
        <el-col
          v-if="!loading && selectedPlan.interval !== 1"
          :xl="24"
          :lg="24"
          :md="24"
          :sm="10"
          :xs="24"
        >
          <el-select
            v-if="paymentMethod === 'card'"
            v-model="selectedInstallment"
            placeholder="Select"
            style="max-width: 400px;"
            class="input-select"
          >
            <el-option
              v-for="item in installments"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-col>
      </el-row>
    </div>
    <div
      v-else
      class="meu-plano"
    >
      <div
        class="meu-plano__resumo"
        style="margin-top:1rem;">
        <p v-if="+planTotalPrice">{{ billetDescription }}.
          <!-- eslint-disable-next-line vue/no-parsing-error -->
          </br>
          Enviaremos o boleto para <b>{{ storeDetails.email }}</b></p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from 'moment';

import * as types from "@/store/types";
import toMoney from "@/helpers/toMoney";

export default {
  filters: {
    toMoney
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    selectedPlan: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    const validateCVV = (rule, value, cb) => {
      const val = value.toString();
      if ((val === "") & !val) {
        return cb(new Error("Digite o CVV"));
      }
      if (val && val.length !== 3) {
        return cb(new Error("CVV têm 3 caractéres"));
      }
      const isValid = Iugu.utils.validateCVV(val, this.currentFlag);
      if (!isValid) {
        return cb(new Error("CVV Inválido"));
      }
      return cb();
    };
    const validateNumber = (rule, value, cb) => {
      this.currentFlag = "";
      if ((value === "") & !value) {
        return cb(new Error("Digite o número do cartão"));
      }
      if (!Iugu.utils.validateCreditCardNumber(value)) {
        return cb(new Error("Número do cartão inválido"));
      } else {
        this.currentFlag = Iugu.utils.getBrandByCreditCardNumber(value);
      }
      return cb();
    };
    const validateExpiration = (rule, value, cb) => {
      if ((value === "") & !value) {
        return cb(new Error("Digite data de expiração"));
      }
      if (!Iugu.utils.validateExpirationString(value)) {
        return cb(new Error("Data de expiração inválida"));
      }
      cb();
    };
    const validateFullname = (rule, value, cb) => {
      if ((value === "") & !value) {
        return cb(new Error("Digite o nome completo"));
      }
      if (value.trim().split(" ").length <= 1) {
        return cb(new Error("Digite o nome e o sobrenome"));
      }
      cb();
    };
    return {
      currentFlag: "",
      paymentMethod: "card",
      selectedInstallment: 1,
      cardFormRules: {
        verification_value: [{ validator: validateCVV, trigger: "blur" }],
        number: [{ validator: validateNumber, trigger: "blur" }],
        expiration: [{ validator: validateExpiration, trigger: "blur" }],
        full_name: [{ validator: validateFullname, trigger: "blur" }]
      },
      cardForm: {
        description: "Pagamento via cartão",
        number: "",
        verification_value: "",
        full_name: "",
        expiration: ""
      },
      paymentMethodsOptions: [
        { id: 1, label: 'Cartão de crédito', value: 'card' },
        { id: 2, label: 'Boleto à vista', value: 'billet' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      authOrigin: types.AUTH_ORIGIN,
      storeDetails: types.AUTH_ACTIVE_STORE_DETAILS,
      currentPlan: types.SUBSCRIPTION_DETAILS,
    }),
    planTotalPrice() {
      const { interval } = this.selectedPlan;
      switch (interval) {
        case 12:
          return this.selectedPlan.price * 12;
        case 6:
          return this.selectedPlan.price * 6;
        default:
          return this.selectedPlan.price;
      }
    },
    billetDescription() {
      const { interval } = this.selectedPlan;
      let renewalDate = '';
      if (this.currentPlan.due_day) {
        renewalDate = this.currentPlan.due_day;
      } else {
        renewalDate = moment().format('DD');
      }
      switch (interval) {
        case 12:
          return `Um boleto no valor de ${toMoney(this.selectedPlan.price * 12)} será gerado`;
        case 6:
          return `Um boleto no valor de ${toMoney(this.selectedPlan.price * 6)} será gerado`;
        default:
          return `Seu plano será renovado automaticamente todo  dia ${renewalDate} e serão cobrados ${toMoney(this.selectedPlan.price)}`;
      }
    },
    installments() {
      const { price, interval } = this.selectedPlan;
      const fullPrice = price * interval;
      switch (interval) {
        case 12:
          return [
            { id: 1, label: ` à vista ${toMoney(fullPrice)}`, value: 1 },
            { id: 2, label: ` 2x de ${toMoney(fullPrice / 2)} sem juros`, value: 2 },
            { id: 3, label: ` 3x de ${toMoney(fullPrice / 3)} sem juros`, value: 3 },
            { id: 4, label: ` 4x de ${toMoney(fullPrice / 4)} sem juros`, value: 4 },
            { id: 5, label: ` 5x de ${toMoney(fullPrice / 5)} sem juros`, value: 5 },
            { id: 6, label: ` 6x de ${toMoney(fullPrice / 6)} sem juros`, value: 6 },
            { id: 7, label: ` 7x de ${toMoney(fullPrice / 7)} sem juros`, value: 7 },
            { id: 8, label: ` 8x de ${toMoney(fullPrice / 8)} sem juros`, value: 8 },
            { id: 9, label: ` 9x de ${toMoney(fullPrice / 9)} sem juros`, value: 9 },
            { id: 10, label: `10x de ${toMoney(fullPrice / 10)} sem juros`, value: 10 },
            { id: 11, label: `11x de ${toMoney(fullPrice / 11)} sem juros`, value: 11 },
            { id: 12, label: `12x de ${toMoney(this.selectedPlan.price)} sem juros`, value: 12 },
          ];
        case 3:
          return [
            { id: 1, label: ` à vista ${toMoney(fullPrice)}`, value: 1 },
            { id: 2, label: ` 2x de ${toMoney(fullPrice / 2)} sem juros`, value: 2 },
            { id: 3, label: ` 3x de ${toMoney(fullPrice / 3)} sem juros`, value: 3 },
            // { id: 4, label: ` 4x de ${toMoney(fullPrice / 4)} sem juros`, value: 4 },
            // { id: 5, label: ` 5x de ${toMoney(fullPrice / 5)} sem juros`, value: 5 },
            // { id: 6, label: ` 6x de ${toMoney(fullPrice / 6)} sem juros`, value: 6 },
          ]
        default:
          return [];
      }
    },
    renewalDate() {
      if (this.currentPlan.due_day) {
        return this.currentPlan.due_day;
      }
      return moment().format('DD');
    },
  },
  watch: {
    selectedPlan: function(value) {
      this.selectedInstallment = 1;
      if (value.interval > 1) {
        return this.$emit(
          'onChange',
          {
            installment: this.getSelectedInstallment(1, this.paymentMethod),
          },
        );
      }
      return this.$emit(
        'onChange',
        {
          installment: { id: 1, label: ` à vista`, value: 1 },
        },
      );
    },
    paymentMethod: function(value) {
      this.$emit(
        'onChange',
        {
          method: value,
        },
      );
    },
    selectedInstallment: function(value) {
      if (value > 1) {
        return this.$emit(
          'onChange',
          {
            installment: this.getSelectedInstallment(value, this.paymentMethod),
          },
        );
      }
      return this.$emit(
        'onChange',
        {
          installment: { id: 1, label: ` à vista`, value: 1 },
        },
      );
    },
  },
  methods: {
    handleValidate(value, valid) {
      this.$emit(
        'onCardChange',
        { [value]: { value: this.cardForm[value], valid } }
      );
    },
    getSelectedInstallment(installment, method) {
      if (method === 'billet') {
        return this.installments[0];
      }
      return this.installments.find(item => item.id === installment);
    }
  }
}
</script>

<style lang="scss">
.payment-methods-container {
  .disclaimer {
    margin-bottom: 1rem;
    font-size: 14px;
    line-height: 1.5;
    color: $preto;
    opacity: 0.75;
  }
  .card-form {
    margin: 2rem auto 0 auto;
    .el-col {
      position: relative;
    }
    .el-input {
      margin-left: 0;
    }
    i {
      display: inline-block;
      background-size: 100% 100%;
      position: absolute;
      width: 22px;
      height: 22px;
      background: #ccc;
      left: 8px;
      top: 7px;
      z-index: 2;
      transition: background-position 0.2s ease-in;
    }
    input {
      padding-left: 38px;
    }
    i.number {
      background: url("http://storage.pupui.com.br/9CA0F40E971643D1B7C8DE46BBC18396/assets/ccic-brands.48dba03883007f86e118f683dcfc4297.png")
        no-repeat;

      &.visa {
        background-position: 0px -22px;
      }
      &.mastercard {
        background-position: 0px -66px;
      }
    }
    i.verification_value {
      background: url("http://storage.pupui.com.br/9CA0F40E971643D1B7C8DE46BBC18396/assets/ccic-cvv.1fe78dcc390427094bdc14dedea10f34.png")
        no-repeat;
    }
    i.full_name {
      background: url("http://storage.pupui.com.br/9CA0F40E971643D1B7C8DE46BBC18396/assets/ccic-name.1cafa1882fdd56f8425de54a5a5bbd1e.png")
        no-repeat;
    }
    i.expiration {
      background: url("http://storage.pupui.com.br/9CA0F40E971643D1B7C8DE46BBC18396/assets/ccic-exp.05e708b1489d5e00c871f20ba33bbff3.png")
        no-repeat;
    }
  }
  .meu-plano {
    margin: 0 auto;
    text-align: center;
    font-family: $title-font;
  }
  .plan-title {
    width: fit-content;
    margin: 0 auto 0.5rem;
    text-transform: uppercase;
    font-weight: bold;
    padding: 4px 1rem;
    border-radius: 12px;
    color: #fff;
  }
  .plan-price {
    font-weight: bold;
    margin-bottom: 1rem;
  }
  .meu-plano__resumo{
    word-break: break-word;
    p{
    line-height: 1.3rem;
    }
  }
  .input-select {
    width: 100% !important;
  }
}
</style>
