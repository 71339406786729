<template>
  <div
    class="plans-period"
  >
    <a
      v-smooth-scroll="{ duration: 1000, offset: -80 }"
      href="#payment-method"
      style="text-decoration: none;"
      @click="$emit('onSelect', yearPlan)"
    >
      <el-card
        :class="{ 'active-period': selectedPeriod.interval === 12 }"
      >
        <div class="plans-perido-content">
          <h2>Anual</h2>
          <h1>{{ yearPlan.price | formatMoney }}<small>/mês</small></h1>
          <small>Total a pagar {{ (yearPlan.price * 12) | formatMoney }} à vista</small>
          <p class="discount">{{ calculatePercentage(yearPlan.price) }}% de desconto</p>
        </div>
      </el-card>
    </a>
    <a
      v-smooth-scroll="{ duration: 1000, offset: -80 }"
      href="#payment-method"
      style="text-decoration: none;"
      @click="$emit('onSelect', sixMonthPlan)"
    >
      <el-card
        :class="{ 'active-period': selectedPeriod.interval === 6 }"
      >
        <div class="plans-perido-content">
          <h2>Semestral</h2>
          <h1>{{ sixMonthPlan.price | formatMoney }}<small>/mês</small></h1>
          <small>Total a pagar {{ (sixMonthPlan.price * 6) | formatMoney }} à vista</small>
          <p class="discount">{{ calculatePercentage(sixMonthPlan.price) }}% de desconto</p>
        </div>
      </el-card>
    </a>
    <a
      v-smooth-scroll="{ duration: 1000, offset: -80 }"
      href="#payment-method"
      style="text-decoration: none;"
      @click="$emit('onSelect', activePlan)"
    >
      <el-card
        :class="{ 'active-period': selectedPeriod.interval === 3 }"
        style="min-height: 162px;"
      >
        <div class="plans-perido-content">
          <h2>Trimestral</h2>
          <h1>{{ activePlan.price | formatMoney }}<small>/mês</small></h1>
        </div>
      </el-card>
    </a>
  </div>
</template>

<script>
import formatMoney from "@/helpers/toMoney";
export default {
  filters: {
    formatMoney,
  },
  props: {
    activePlan: {
      type: Object,
      required: true,
    },
    selectedPeriod: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    yearPlan() {
      return this.activePlan.sub_plans.find((plan) => plan.interval === 12);
    },
    sixMonthPlan() {
      return this.activePlan.sub_plans.find((plan) => plan.interval === 6);
    }
  },
  methods: {
    calculatePercentage(value) {
      const { price } = this.activePlan;
      const difference = price - value;
      const percentage = (difference / price) * 100;
      return Math.floor(percentage);
    }
  }
}
</script>

<style lang="scss" scoped>
.plans-period {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 22px;
  margin-top: 1.5rem;
  .el-card {
    cursor: pointer;
    transition: 0.4s;
    border: solid 2px #FFF;
    filter: opacity(30%);
    &:hover {
      border-color: $azul;
      transform: translateY(-10px);
    }
  }
  .active-period {
    border-color: $azul;
    transform: translateY(-10px);
    filter: opacity(100%);
  }
  .plans-perido-content {
    text-align: center;
    h1 {
      margin: 0;
      font-size: 48px;
      @media screen and(max-width: 768px) {
        font-size: 2rem;
      }
      small {
        font-size: 13px;
      }
    }
    small {
      color: $preto;
      opacity: 0.6;
    }
    .discount {
      margin: 0;
      color: #67c23a;
    }
  }
  @media screen and (max-width: 768px) {
    grid-row-gap: 5px;
  }
  @media screen and (max-width: 767px) {
    grid-template-columns: 100%;
  }
  @media screen and (max-width: 375px) {
    .plans-perido-content {
      h1 {
        font-size: 38px;
      }
    }
  }
}
</style>
