<template>
  <section class="subscription-overview">
    <el-row v-if="$route.query.origin !== 'app'">
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="24"
        :xl="24"
        class="subscription-overview--logo">
        <div class="wrapper">
          <div class="custom-flex">
            <Logo
              :alternative="false"
              width="161" />
            <el-button
              v-if="!trialInfo"
              type="primary"
              round
              size="small"
              @click="$router.push('/')"
            >{{ $isMobile ? 'Voltar' : 'Voltar para Neopag' }}</el-button>
          </div>
        </div>
      </el-col>
    </el-row>
    <div
      v-if="!submitted"
      class="wrapper">
      <el-card
        v-if="trialInfo && trialInfo.days_remaining <= 0"
        class="subscription-overview--intro"
      >
        <el-row>
          <el-col
            :xs="7"
            :sm="3"
            :md="3"
            :lg="3"
            :xl="3">
            <img
              src="@/assets/calender.png"
              class="subscription-overview--intro__calender-img">
          </el-col>
          <el-col
            :xs="15"
            :sm="12"
            :md="9"
            :lg="9"
            :xl="9"
            :offset="2">
            <h2 class="subscription-overview--intro__title">Seu período de teste acabou!</h2>
            <p
              class="subscription-overview--intro__text"
            >Veja abaixo o melhor plano para sua loja e continue aumentando suas vendas e fidelizando seus clientes!</p>
          </el-col>
        </el-row>
      </el-card>
      <el-row
        :gutter="12">
        <el-col
          :xs="24"
          :sm="12"
          :md="12"
          :lg="12"
          :xl="12"
        >
          <div class="subscription-overview--intro">
            <h2 class="subscription-overview--intro__title">O plano ideal para você!</h2>
            <p class="subscription-overview--intro__text">Todos os planos possuem uma solução eficiente para a gestão do crédito próprio com Análise de Crédito, Gestão Poderosa e Cobranças Automatizadas.</p>
          </div>
        </el-col>
        <el-col
          :xs="22"
          :sm="10"
          :md="10"
          :lg="10"
          :xl="10"
          :offset="2">
          <div class="subscription-overview--intro-custom">
            <el-col
              :xl="6"
              :lg="6"
              :md="6"
              :sm="8"
              :xs="6">
              <img
                class="subscription-overview--intro-custom__img"
                src="@/assets/call-to-us.png">
            </el-col>
            <el-col
              :xl="18"
              :lg="18"
              :md="18"
              :sm="15"
              :xs="16">
              <h2 class="subscription-overview--intro-custom__title">Precisa de ajuda?</h2>
              <p
                class="subscription-overview--intro-custom__text"
              >Ligue agora <b>(11) 3090-8554</b> e fale com um dos nossos especialistas, ou se preferir, ligamos pra você.</p>
              <a
                href="https://share.hsforms.com/189XR6uNYTJmFn9dyc3cvwQ3qc56"
                target="_blank">
                <el-button
                  type="text"
                >Receba uma ligação agora!</el-button>
              </a>
            </el-col>
          </div>
        </el-col>
      </el-row>
      <el-row v-loading="fetching">
        <el-col
          v-for="plan in groupedPlans"
          :key="plan.id"
          :xs="24"
          :sm="8"
          :md="8"
          :lg="8"
          :xl="8"
        >
          <subscription-plan-card
            :plan="plan"
            :loading="fetching"
            :active-plan="activePlan"
            class="subscription-overview--plan-card"
            @onSelect="handleSelectPlan"
          />
        </el-col>
        <el-col
          :xl="12"
          :lg="12"
          :md="12"
          :sm="12"
          :xs="24">
          <p class="subscription-overview--quotes__info"><strong>Já somos mais de 10.000 Neopagers </strong>utilizando a plataforma entre lojistas e clientes dos lojistas</p>
        </el-col>
        <el-col
          :xl="12"
          :lg="12"
          :md="12"
          :sm="12"
          :xs="24">
          <blockquote class="subscription-overview--quotes__blockquote">
            <p class="subscription-overview--quotes__blockquote--p">Com a Neopag consigo vender parcelado para os meus clientes com confiança."</p>
            <footer><strong>Cleber Lucio</strong>, <cite>Outlet Imports</cite></footer>
          </blockquote>
        </el-col>
      </el-row>
      <el-row
        v-loading="fetching"
        v-if="activePlan.sub_plans">
        <SubscriptionPlansPeriod
          id="plan-period"
          :selected-period="selectedPeriod"
          :active-plan="activePlan"
          :installment-number="installmentNumber"
          @onSelect="plan => selectedPeriod = plan"
        />
      </el-row>
      <el-row
        :gutter="12"
        class="subscription-overview--payment">
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24">
          <el-card>
            <h2 class="subscription-overview--payment__title">Meio de pagamento</h2>
            <subscription-payment-methods
              id="payment-method"
              :loading="fetching"
              :selected-plan="selectedPeriod"
              class="subscription-overview--payment__form"
              @onChange="handlePaymentMethodChange"
              @onCardChange="handleCardChange"
            />
          </el-card>
        </el-col>
      </el-row>
      <el-row class="subscription-overview--checkout">
        <el-card
          v-loading="fetching"
        >
          <h2>Resumo da compra</h2>
          <div class="custom-flex">
            <div>
              <p class="subscription-overview--checkout__name">{{ selectedPeriod.name }}</p>
              <p class="subscription-overview--checkout__value">{{ paymentLabel }}</p>
            </div>
            <div class="subscription-overview--plan-details">
              <div class="subscription-overview--plan-details__card">
                <ul class="subscription-overview--plan-details__list-of-benefits">
                  <li
                    v-for="feature in selectedPeriod.features"
                    :key="feature"
                    class="list-of-benefits--item"
                  >
                    <font-awesome-icon
                      icon="check"
                      color="#67c23a" />
                    {{ feature }}
                  </li>
                </ul>
              </div>
            </div>
              <el-button
                v-loading="submitting"
                class="subscription-overview--checkout__submit-button"
                type="success"
                @click="handleSubmit"
              >Confirmar contratação</el-button>
          </div>
          <p class="terms">
            <small>
              Ao clicar em "Confirmar contratação" você concorda com os
              <a
                href="https://www.neopag.com/termos-de-uso-site"
                target="_blank"
                rel="noreferrer"
              >Termos de uso</a> e o <a
                href="https://www.neopag.com/contrato-loja"
                target="_blank"
                rel="noreferrer"
              >Contrato de Prestação de Serviços</a> da Neopag
            </small>
          </p>
        </el-card>
      </el-row>
    </div>
    <el-row
      v-if="scroll > 333 && !isMobile && !submitted"
      class="subscription-overview--checkout-fixed">
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="24"
        :xl="24">
        <el-card
          v-loading="fetching"
        >
          <div class="custom-flex">
            <span class="subscription-overview--checkout-fixed__text">Resumo da compra</span>
            <div class="subscription-overview--checkout-fixed--info-wrapper">
              <p class="subscription-overview--checkout-fixed__name">{{ selectedPeriod.name }}</p>
              <p class="subscription-overview--checkout-fixed__value">{{ paymentLabel }}</p>
            </div>
              <el-button
                v-loading="submitting"
                type="success"
                size="small"
                round
                @click="handleSubmit"
              >Confirmar contratação</el-button>
          </div>
          <p class="terms">
            <small>
              Ao clicar em "Confirmar contratação" você concorda com os
              <a
                href="https://www.neopag.com/termos-de-uso-site"
                target="_blank"
                rel="noreferrer"
              >Termos de uso</a> e o <a
                href="https://www.neopag.com/contrato-loja"
                target="_blank"
                rel="noreferrer"
              >Contrato de Prestação de Serviços</a> da Neopag
            </small>
          </p>
        </el-card>
      </el-col>
    </el-row>
    <div
      v-if="submitted"
      class="wrapper submitted-wrapper">
      <div class="check-wrapper">
        <font-awesome-icon
          icon="check"
          color="#67c23a" />
      </div>
      <h1>Contratação efetuada!</h1>
      <el-button
        type="primary"
        round
        size="small"
        @click="handleNavigationClick">Voltar para Neopag</el-button>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import _ from "lodash";

import * as types from "@/store/types";
import formatMoney from "@/helpers/toMoney";

import Logo from "@/components/Logo";
import SubscriptionPlanCard from "@/components/Subscription/SubscriptionPlanCard";
import SubscriptionPaymentMethods from "@/components/Subscription/SubscriptionPaymentMethods";
import SubscriptionPlansPeriod from "@/components/Subscription/SubscriptionPlansPeriod";

library.add(faCheck);

export default {
  components: {
    Logo,
    SubscriptionPlanCard,
    SubscriptionPaymentMethods,
    SubscriptionPlansPeriod,
    FontAwesomeIcon
  },
  data() {
    return {
      showPlans: false,
      showPeriod: false,
      fetching: false,
      submitting: false,
      submitted: false,
      groupedPlans: [],
      promotionalCode: "",
      activePlan: {
        color: "",
        start: undefined,
        name: "",
        price: 0
      },
      payment: {
        card: {
          number: { valid: false },
          verification_value: { valid: false },
          full_name: { valid: false },
          expiration: { valid: false }
        },
        method: "card",
        installment: {
          value: 1,
          label: "à vista"
        }
      },
      trialInfo: true,
      selectedPeriod: {},
      scroll: 0,
      isMobile: false
    };
  },
  computed: {
    ...mapGetters({
      token: types.AUTH_TOKEN,
      plans: types.SUBSCRIPTION_PLANS,
      subscription: types.SUBSCRIPTION_DETAILS,
      storeID: types.AUTH_ACTIVE_STORE,
      paymentMethods: types.SUBSCRIPTION_PAYMENT_METHODS
    }),
    paymentLabel() {
      if (this.payment.method === "card") {
        if (this.selectedPeriod.interval > 1) {
          const { label } = this.payment.installment;
          const totalPrice =
            this.selectedPeriod.price * this.selectedPeriod.interval;
          return `${formatMoney(totalPrice)} ${
            this.payment.installment.value > 1 ? `em ${label}` : "à vista"
          } no cartão de crédito.`;
        }
        return `${formatMoney(
          this.selectedPeriod.price
        )} à vista no cartão de crédito.`;
      }
      if (this.selectedPeriod.interval > 1) {
        return `${formatMoney(
          this.selectedPeriod.price * this.selectedPeriod.interval
        )} à vista no boleto.`;
      }
      return `${formatMoney(this.selectedPeriod.price)} à vista no boleto.`;
    },
    installmentNumber() {
      if (this.payment && this.payment.installment) {
        if (this.payment.installment.value > 1) {
          return `em ${this.payment.installment.label}`;
        }
        return this.payment.installment.label;
      }
      return "à vista";
    },
    activePaymentMethod() {
      return this.paymentMethods[this.paymentMethods.length - 1];
    },
    planPrice() {
      return this.$n(this.activePlan.price, "currency");
    },
    disableSubmit() {
      if (this.payment.method === "card") {
        const { card } = this.payment;
        const isValid = Object.values(card).every(item => item.valid === true);
        return !isValid;
      }
      return false;
    },
  },
  async mounted() {
    window.innerWidth < 768 ? (this.isMobile = true) : (this.isMobile = false);
    this.fetching = true;
    try {
      const trialInfo = await this.$store.dispatch(
        types.STORE_TRIAL_STATUS,
        this.storeID
      );
      await this.$store.dispatch(types.SUBSCRIPTION_PLANS);
      await this.$store.dispatch(types.SUBSCRIPTION_DETAILS, this.storeID);
      await this.$store.dispatch(
        types.SUBSCRIPTION_PAYMENT_METHODS,
        this.storeID
      );
      let plans = {};
      this.plans.forEach(plan => {
        if (!plans[plan.mini_label]) {
          return (plans = {
            ...plans,
            [plan.mini_label]: {
              ...plan,
              sub_plans: [plan]
            }
          });
        }
        if (plans[plan.mini_label]) {
          plans[plan.mini_label].sub_plans.push(plan);
          if (plan.interval === 3) {
            return (plans[plan.mini_label] = {
              ...plans[plan.mini_label],
              ...plan
            });
          }
        }
      });
      this.trialInfo = trialInfo;
      this.groupedPlans = Object.values(plans);
      this.activePlan = plans["COMPLETO"];
      // this.selectedPeriod = plans['COMPLETO'];
      this.selectedPeriod = plans["COMPLETO"].sub_plans.find(
        plan => plan.interval === 3
      );
    } catch (error) {
      this.fetching = false;
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({ title: error.message });
    }
    this.fetching = false;
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll: _.debounce(function() {
      this.scroll = window.scrollMaxY - window.scrollY;
    }, 10),
    toMoney(value) {
      return formatMoney(value);
    },
    handleSelectPlan(plan) {
      this.activePlan = plan;
      if (plan.sub_plans.length > 1) {
        this.selectedPeriod = plan.sub_plans.find(plan => plan.interval === 3);
        return (this.showPeriod = true);
      }
      this.selectedPeriod = plan;
    },
    handlePaymentMethodChange(data) {
      this.payment = { ...this.payment, ...data };
    },
    handleCardChange(data) {
      this.payment = {
        ...this.payment,
        card: {
          ...this.payment.card,
          ...data
        }
      };
    },
    async handleSubmit() {
      if (this.disableSubmit) {
        this.$notify.warning({
          title: "Preencha os dados de pagamento antes de continuar."
        });
        return;
      }
      this.fetching = true;
      if (this.payment.method === "card") {
        const {
          number,
          verification_value,
          full_name,
          expiration
        } = this.payment.card;
        const names = full_name.value.split(" ");
        const dates = moment(expiration.value, "DD/YY")
          .format("DD/YYYY")
          .split("/");
        const creditCard = Iugu.CreditCard(
          number.value,
          dates[0],
          dates[1],
          names[0],
          names[1],
          verification_value.value
        );
        return Iugu.createPaymentToken(creditCard, this.tokenCallback);
      }
      await this.$store.dispatch(types.SUBSCRIPTION_PAYMENT_METHODS_UPDATE, {
        storeID: this.storeID,
        default_payment_method_id: null
      });
      await this.$store.dispatch(types.SUBSCRIPTION_UPDATE, {
        storeID: this.storeID,
        planID: this.selectedPeriod.id,
        paymentId: null,
        promotionalCode: this.promotionalCode
      });
      await this.$store.dispatch(types.SUBSCRIPTION_DETAILS, this.storeID);
      this.fetching = false;
      this.submitted = true;
    },
    async tokenCallback(data) {
      try {
        const response = await this.$store.dispatch(
          types.SUBSCRIPTION_PAYMENT_METHODS_CREATE,
          {
            store_id: this.storeID,
            description: "Cartão de crédito",
            token: data.id,
            installments: this.payment.installment.value
          }
        );
        const { id } = response.data;
        await this.$store.dispatch(types.SUBSCRIPTION_UPDATE, {
          storeID: this.storeID,
          planID: this.selectedPeriod.id,
          paymentId: id
        });
        this.submitted = true;
      } catch (error) {
        this.fetching = false;
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
      this.fetching = false;
    },
    handleNavigationClick() {
      if (this.$route.query.origin === 'app') return NativeAppChannel.postMessage('success!');
      return this.$router.push('/');
    }
  }
};
</script>

<style lang="scss">
.subscription-overview {
  overflow-x: none;
  &--logo {
    background-color: $preto;
    .custom-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .wrapper {
    max-width: 1024px;
    margin: 0 auto;
    @media screen and (max-width: 992px) {
      padding: 0 0.5rem;
    }
  }
    .subscription-overview--intro {
      margin-top: 1rem;
      padding: 1rem;
      &__title {
        font-size: 1.2rem;
        font-weight: bold;
      }
      &__text {
        font-size: 0.9rem;
        margin-top: 0.6rem;
      }
      &__calender-img {
        max-width: 80px;
        max-height: 80px;
      }
    }
    .subscription-overview--intro-custom {
      margin-top: 1rem;
      padding: 1rem 0;
      &__img{
        max-width: 70px;
        max-height: 70px;
        border-radius: 30rem;
      }
      &__title {
        font-size: 1.2rem;
        font-weight: bold;
      }
      &__text {
        font-size: 0.9rem;
        margin-top: 0.6rem;
        // position: relative;
        // &:before {
        //   content: " ";
        //   position: absolute;
        //   width: 1px;
        //   height: 70px;
        //   background-color: $preto;
        //   bottom: 0;
        //   left: -1rem;
        // }
      }
    }
    .subscription-overview--ideal-plan {
      margin-top: 1rem;
    }
    .subscription-overview--plan-card {
      margin-top: 2rem;
    }
    .subscription-overview--quotes{
      margin-top: 1rem;
      &__info, &__blockquote {
        padding: 1rem 2rem;
        @media screen and(min-width: 769px) {
        padding: 2rem 5rem;
        }
      }
      &__info {
        font-size: 0.8rem;
        strong {
          color: #67c23a;
        }
      }
      &__blockquote {
        font-size: 0.9rem;
            color: $preto;
            &--p{
        position: relative;
        &::before {
          font-family: 'IM Fell DW Pica', serif;
          content: ' " ';
          font-size: 2rem;
          color: $preto;
          position: absolute;
          top: -10px;
          left: -1rem;
        }
            }
        footer {
          font-size: 0.8rem;
        }
      }
    }
    .subscription-overview--payment {
      margin-top: 1rem;
      &__form {
        margin-top: 1rem;
      }
    }
    .subscription-overview--checkout-fixed {
      margin-top: 1rem;
      z-index: 99999;
      position: sticky;
      bottom: 0;
      .custom-flex {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
      }
      &__text {
        font-weight: bold;
        color: $preto;
      }
      &__name {
        font-weight: bold;
        color: #67c23a;
        text-align: center;
      }
      &__value {
        color: $preto;
        text-align: center;
      }
    }
    .subscription-overview--checkout {
      margin-top: 1rem;
      margin-bottom: 2rem;
      .custom-flex {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-evenly;
        align-content: center;
      }
      &__text {
        font-weight: bold;
        color: $preto;
      }
      &__name {
        font-weight: bold;
        color: #67c23a;
        margin-top: 1rem;
      }
      &__value {
        color: $preto;
        margin: 1rem 0;
        max-width: 320px;
        word-break: break-word;
      }
      &__submit-button {
        padding: 1rem 2rem;
        @media screen and(max-width: 768px) {
          margin-top: 1rem;
        }
      }
      .subscription-overview--plan-details {
        font-family: "Montserrat";
        &__title {
        }
        &__list-of-benefits {
          position: relative;
          list-style: none;
          margin-top: 1rem;
          .list-of-benefits--item {
            margin: 0.5rem 0;
          }
          &::before {
            content: " ";
            position: absolute;
            width: 1px;
            height: 180px;
            background-color: $preto;
            left: -0.5rem;
            top: 0;
            opacity: 0.4;
          }
        }
      }
    }
    .planos {
      text-align: center;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 16px;
      @media screen and (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media screen and (max-width: 425px) {
        grid-template-columns: 100%;
      }
    }
    .top-cards-wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 16px;
      grid-row-gap: 16px;
      margin-bottom: 32px;
      @media screen and (max-width: 425px) {
        grid-template-columns: 100%;
      }
    }
    .promotional-card {
      margin-top: 32px;
      .el-input {
        margin-top: 16px;
        max-width: 400px;
      }
    }
    .checkout-card {
      margin-top: 32px;
      z-index: 9999;
      .resume-list {
        list-style-type: none;
        li {
          display: flex;
          flex-flow: row wrap;
          align-items: center;
          justify-content: space-between;
          padding-top: 22px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          p {
            font-size: 18px;
          }
        }
      }
    }
    .checkout-card-summary {
      @media screen and (min-width: 987px) {
        position: sticky;
        bottom: 5px;
        z-index: 6;
      }
      .submit-wrapper {
        p {
          margin: 0 22px 0 0;
        }
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        align-items: center;
        justify-items: center;
        @media screen and (max-width: 987px) {
          grid-template-columns: 1fr;
          grid-template-rows: 1fr 1fr 1fr;
          justify-items: auto;
        }
        @media screen and (max-width: 425px) {
          .el-button {
            margin: 16px 0;
            width: 100%;
          }
        }
        .submit-summary-text,
        .submit-summary-plan-name,
        .submit-summary-plan-value {
          // @media screen and (min-width: 987px) {
          //   &:after {
          //     content: " ";
          //     position: absolute;
          //     width: 1px;
          //     height: 55px;
          //     background-color: $preto;
          //     top: -1rem;
          //     right: -30%;
          //   }
          // }
        }
      }
    }
    .submitted-wrapper {
      max-width: 1024px;
      height: calc(100vh - 65px);
      margin: 0 auto;
      display: flex;
      flex: 1;
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;
      .check-wrapper {
        border-radius: 100%;
        width: 250px;
        height: 250px;
        border: solid 2px #67c23a;
        font-size: 128px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .terms {
        margin-top: 8px;
        text-align: center;
        small {
          opacity: 0.6;
        }
        a {
          color: $laranja;
          font-weight: 700;
          text-decoration: none;
        }
      }
  }
</style>
